var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "docsify-body" },
    [
      _c(
        "a-row",
        { staticClass: "docsify-body-row", attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            [_c("Menu", { attrs: { "can-drag": _vm.isDragMode } })],
            1
          ),
          _c(
            "a-col",
            { staticClass: "docsify-body-col" },
            [
              [
                _vm.isEditMode
                  ? _c("docsify-editor", {
                      model: {
                        value: _vm.content,
                        callback: function ($$v) {
                          _vm.content = $$v
                        },
                        expression: "content",
                      },
                    })
                  : _c("div", {
                      staticClass: "docsify-content",
                      domProps: { innerHTML: _vm._s(_vm.content) },
                    }),
              ],
              _c(
                "a-row",
                {
                  staticClass: "docsify-button-group",
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button-group",
                        [
                          _c("a-button", {
                            attrs: {
                              type: _vm.isDragMode ? "primary" : "default",
                              icon: "drag",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isDragMode = !_vm.isDragMode
                              },
                            },
                          }),
                          _vm.isEditMode
                            ? _c("a-button", {
                                attrs: { type: "primary", icon: "save" },
                                on: { click: _vm.startSave },
                              })
                            : _vm._e(),
                          !_vm.isEditMode
                            ? _c("a-button", {
                                attrs: { type: "default", icon: "edit" },
                                on: { click: _vm.startEdit },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }