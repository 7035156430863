<template>
  <div class="docsify-body">
    <a-row class="docsify-body-row" type="flex">
      <a-col>
        <Menu :can-drag="isDragMode" />
      </a-col>
      <a-col class="docsify-body-col">
        <template>
          <docsify-editor v-if="isEditMode" v-model="content" />
          <div v-else v-html="content" class="docsify-content"></div>
        </template>

        <a-row type="flex" justify="end" class="docsify-button-group">
          <a-col>
            <a-button-group>
              <a-button :type="isDragMode ? 'primary' : 'default'" icon="drag" @click="isDragMode = !isDragMode">
              </a-button>
              <a-button v-if="isEditMode" type="primary" icon="save" @click="startSave"> </a-button>
              <a-button v-if="!isEditMode" type="default" icon="edit" @click="startEdit"> </a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Menu from './menu'
import DocsifyEditor from './docsify-editor'
export default {
  components: {
    Menu,
    DocsifyEditor,
  },
  data() {
    return {
      isEditMode: false,
      isDragMode: false,
      content: '',
    }
  },
  methods: {
    startEdit() {
      this.isEditMode = true
    },
    startSave() {
      this.isEditMode = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.docsify-body {
  background: #fff;
  position: relative;

  .docsify-body-row {
    flex-wrap: nowrap;

    .docsify-body-col {
      flex: 1;
    }
  }
}

.docsify-button-group {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.docsify-content {
  height: calc(100vh - 112px);
  box-sizing: border-box;
  word-break: break-all;
  padding: 20px 24px 20px 20px;
  overflow-y: auto;
}

.docsify-content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.docsify-content:hover {
  padding-right: 20px;
}

.docsify-content:hover::-webkit-scrollbar {
  width: 12px;
}
</style>